// // App.js
// import React, { useState, useEffect, useCallback } from "react";
// import { Route, Routes } from "react-router-dom";
// import { useTelegram } from "./hooks/useTelegram";

// const products = [
//   { id: "1", title: "Джинсы", price: 5000, description: "Синего цвета, прямые" },
//   { id: "2", title: "Куртка", price: 12000, description: "Зеленого цвета, теплая" },
//   { id: "3", title: "Джинсы 2", price: 5000, description: "Синего цвета, прямые" },
//   { id: "4", title: "Куртка 8", price: 122, description: "Зеленого цвета, теплая" },
//   { id: "5", title: "Джинсы 3", price: 5000, description: "Синего цвета, прямые" },
//   { id: "6", title: "Куртка 7", price: 600, description: "Зеленого цвета, теплая" },
//   { id: "7", title: "Джинсы 4", price: 5500, description: "Синего цвета, прямые" },
//   { id: "8", title: "Куртка 5", price: 12000, description: "Зеленого цвета, теплая" },
// ];

// const getTotalPrice = (items = []) => {
//   return items.reduce((acc, item) => acc + item.price, 0);
// };

// const Button = (props) => {
//   return <button {...props} className={"button " + props.className} />;
// };

// const Header = () => {
//   const { user, onClose } = useTelegram();
//   return (
//     <div className={"header"}>
//       <Button onClick={onClose}>Закрыть</Button>
//       <span className={"username"}>{user?.username}</span>
//     </div>
//   );
// };

// const ProductItem = ({ product, className, onAdd }) => {
//   const onAddHandler = () => onAdd(product);

//   return (
//     <div className={"product " + className}>
//       <div className="img" />
//       <div className="title">{product.title}</div>
//       <div className="description">{product.description}</div>
//       <div className="price">
//         <span>
//           Стоимость: <b>{product.price}</b>
//         </span>
//       </div>
//       <Button className="add-btn" onClick={onAddHandler}>
//         Добавить в корзину
//       </Button>
//     </div>
//   );
// };

// const ProductList = () => {
//   const [addedItems, setAddedItems] = useState([]);
//   const { tg, queryId } = useTelegram();

//   const onSendData = useCallback(() => {
//     const data = {
//       products: addedItems,
//       totalPrice: getTotalPrice(addedItems),
//       queryId,
//     };
//     fetch("https://api.glcert.ru/web-data", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(data),
//     });
//   }, [addedItems]);

//   useEffect(() => {
//     tg.onEvent("mainButtonClicked", onSendData);
//     return () => {
//       tg.offEvent("mainButtonClicked", onSendData);
//     };
//   }, [onSendData]);

//   const onAdd = (product) => {
//     const alreadyAdded = addedItems.find((item) => item.id === product.id);
//     let newItems = [];

//     if (alreadyAdded) {
//       newItems = addedItems.filter((item) => item.id !== product.id);
//     } else {
//       newItems = [...addedItems, product];
//     }

//     setAddedItems(newItems);

//     if (newItems.length === 0) {
//       tg.MainButton.hide();
//     } else {
//       tg.MainButton.show();
//       tg.MainButton.setParams({
//         text: `Купить ${getTotalPrice(newItems)}`,
//       });
//     }
//   };

//   return (
//     <div className="list">
//       {products.map((item) => (
//         <ProductItem key={item.id} product={item} onAdd={onAdd} className="item" />
//       ))}
//     </div>
//   );
// };

// function App() {
//   const { tg } = useTelegram();

//   useEffect(() => {
//     tg.ready();
//   }, [tg]);

//   return (
//     <div className="App">
//       <Header />
//       <Routes>
//         <Route index element={<ProductList />} />
//       </Routes>
//     </div>
//   );
// }

// export default App;
// App.js

import React, { useState, useEffect, useCallback } from "react";
import { Route, Routes } from "react-router-dom";
import { useTelegram } from "./hooks/useTelegram";
import "./App.css";

const products = [
  { id: "1", title: "Джинсы", price: 5000, description: "Синего цвета, прямые" },
  { id: "2", title: "Куртка", price: 12000, description: "Зеленого цвета, теплая" },
  { id: "3", title: "Джинсы 2", price: 5000, description: "Синего цвета, прямые" },
  { id: "4", title: "Куртка 8", price: 122, description: "Зеленого цвета, теплая" },
  { id: "5", title: "Джинсы 3", price: 5000, description: "Синего цвета, прямые" },
  { id: "6", title: "Куртка 7", price: 600, description: "Зеленого цвета, теплая" },
  { id: "7", title: "Джинсы 4", price: 5500, description: "Синего цвета, прямые" },
  { id: "8", title: "Куртка 5", price: 12000, description: "Зеленого цвета, теплая" },
];

const getTotalPrice = (items = []) => {
  return items.reduce((acc, item) => acc + item.price, 0);
};

const Button = (props) => {
  return <button {...props} className={"button " + props.className} />;
};

const Header = () => {
  const { user, onClose } = useTelegram();
  return (
    <div className={"header"}>
      <Button onClick={onClose}>Закрыть</Button>
      <span className={"username"}>{user?.username}</span>
    </div>
  );
};

const ProductItem = ({ product, className, onAdd }) => {
  const onAddHandler = () => onAdd(product);

  return (
    <div className={"product " + className}>
      <div className="img" />
      <div className="title">{product.title}</div>
      <div className="description">{product.description}</div>
      <div className="price">
        <span>
          Стоимость: <b>{product.price}</b>
        </span>
      </div>
      <Button className="add-btn" onClick={onAddHandler}>
        Добавить в корзину
      </Button>
    </div>
  );
};

const ProductList = () => {
  const [addedItems, setAddedItems] = useState([]);
  const { tg, queryId } = useTelegram();

  const onSendData = useCallback(() => {
    const data = {
      products: addedItems,
      totalPrice: getTotalPrice(addedItems),
      queryId,
    };
    fetch("https://api.glcert.ru/web-data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }, [addedItems]);

  useEffect(() => {
    tg.onEvent("mainButtonClicked", onSendData);
    return () => {
      tg.offEvent("mainButtonClicked", onSendData);
    };
  }, [onSendData]);

  const onAdd = (product) => {
    const alreadyAdded = addedItems.find((item) => item.id === product.id);
    let newItems = [];

    if (alreadyAdded) {
      newItems = addedItems.filter((item) => item.id !== product.id);
    } else {
      newItems = [...addedItems, product];
    }

    setAddedItems(newItems);

    if (newItems.length === 0) {
      tg.MainButton.hide();
    } else {
      tg.MainButton.show();
      tg.MainButton.setParams({
        text: `Купить ${getTotalPrice(newItems)}`,
      });
    }
  };

  return (
    <div className="list">
      {products.map((item) => (
        <ProductItem key={item.id} product={item} onAdd={onAdd} className="item" />
      ))}
    </div>
  );
};

function App() {
  const { tg } = useTelegram();

  useEffect(() => {
    tg.ready();
  }, [tg]);

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route index element={<ProductList />} />
      </Routes>
    </div>
  );
}

export default App;
